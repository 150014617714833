import { PromotionType } from '../../models/voucherType'
import ic_info_active from '../../assets/icons/PersonalVoucherScreen/ic_info_active.svg'
import ic_info from '../../assets/icons/PersonalVoucherScreen/ic_info.svg'

import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector, useCopyToClipboard } from '../../app/hooks'
import { changeRemoveVoucher, changeSelectedVoucher, idVoucherSelected } from './store/slice'
import ic_select from '../../assets/icons/VoucherScreen/ic_select.png'
import ic_noselect from '../../assets/icons/VoucherScreen/ic_noselect.png'
import ic_copy from '../../assets/icons/PersonalVoucherScreen/ic_copy.svg'
import { useEffect, useRef, useState } from 'react'

type Props = {
  item: PromotionType
}

const VoucherCartItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const idVoucher = useAppSelector(idVoucherSelected)
  const [state, setState] = useState({
    showModal: false,
  })

  const modalRef = useRef(null as any)
  const [, copyToClipboard] = useCopyToClipboard()

  const changeState = (key: string, value: string | boolean) => {
    setState((pre: any) => ({
      ...pre,
      [key]: value,
    }))
  }

  const handleSelected = (item: any) => {
    if (item.isEligible) {
      if (idVoucher === item.id) {
        return dispatch(changeRemoveVoucher())
      }
      dispatch(changeSelectedVoucher(item.id))
    }
  }

  const handleClick = (event: any) => {
    const { target } = event
    if (!modalRef.current?.contains(target)) {
      changeState('showModal', false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div
      key={item.id}
      className={`personal-voucher-item ${!item.isEligible ? 'voucher-screen__box__item__disable' : ''}`}
    >
      <div className='personal-voucher-item__border personal-voucher-item__border--top' />
      <div className='personal-voucher-item__border personal-voucher-item__border--bottom' />
      <div className='personal-voucher-item__icon-block'>
        <div className='personal-voucher-item__icon-block'>
          <img src={item.isEligible ? item.icon : item.icon_inactive} alt={item.name} />
        </div>
      </div>
      <div className='personal-voucher-item__content' ref={modalRef}>
        <div className='personal-voucher-item__content__title'>
          <p>{item.name}</p>
          <img
            src={item.isEligible ? ic_info_active : ic_info}
            alt='icon-info'
            onClick={() => changeState('showModal', true)}
          />
        </div>
        <div
          className='mt-2'
          onClick={() => handleSelected(item)}
          style={{ position: 'absolute', right: '2px', top: '40%' }}
        >
          <img width={20} src={idVoucher == item.id && item.isEligible ? ic_select : ic_noselect} />
        </div>
        <p className='personal-voucher-item__content__info'>{item.description}</p>
        {!item.isEligible ? (
          <p className='personal-voucher-item__content__time-expired'>{t('userVoucher.notEnoughCondition')}</p>
        ) : (
          <p className='personal-voucher-item__content__time'>
            {t('userVoucher.expiryDate')}: {item.end_time}
          </p>
        )}

        <div
          className={`personal-voucher-item__content__detail ${state.showModal ? 'active' : ''}`}
          style={{ width: 'auto' }}
        >
          <div className='personal-voucher-item__content__detail__code'>
            <p>{t('userVoucher.code')}</p>
            <span>{item.promotion_code}</span>
            <img
              src={ic_copy}
              alt='icon-copy'
              onClick={() => typeof copyToClipboard === 'function' && copyToClipboard(item.promotion_code ?? '')}
            />
          </div>
          <div className='personal-voucher-item__content__detail__time'>
            <p className='personal-voucher-item__content__detail__time__label'>{t('userVoucher.expiry')}</p>
            {!item.isEligible ? (
              <p className='personal-voucher-item__content__time-expired'>{t('userVoucher.notEnoughCondition')}</p>
            ) : (
              <p className='personal-voucher-item__content__time'>{item.end_time}</p>
            )}
          </div>
          <p className='personal-voucher-item__content__detail__condition-label'>{t('userVoucher.condition')}</p>
          {item?.rules &&
            item?.rules.map((val, idx) => (
              <div className='personal-voucher-item__content__detail__condition-info' key={idx}>
                <span />
                <p>{val.name}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default VoucherCartItem
