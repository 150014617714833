export const API_ROOT = process.env.REACT_APP_API_URL as string
export const API_BANK = process.env.REACT_APP_BANK_API_URL as string

class Network {
  public static readonly API_ROOT = API_ROOT
  public static readonly common = {
    city: {
      getList: '/pharma/province',
      getItem: (id: string | number) => `/pharma/province/${id}`,
    },
    district: {
      getList: '/pharma/district/search',
      getItem: (id: string | number) => `pharma/district/${id}`,
    },
    ward: {
      getList: '/pharma/ward/search',
      getItem: (id: string | number) => `pharma/ward/${id}`,
    },
    cancelSuggestReason: '/pharma/reason-template/cancel',
    returnSuggestReason: '/pharma/reason-template',
    reviewSuggest: '/pharma/review-suggest',
  }
  public static readonly auth = {
    login: '/auth/login',
    verify: '/auth/verify',
    verifyCheck: '/auth/verify-check',
    register: '/auth/signup',
    loginSocial: '/auth/login-social',
    forgotPass: '/auth/forgot-pass',
    changePass: '/auth/change-pass',
    updateUser: '/auth/update-user',
    getTemToken: '/pharma/setting/get-token',
  }
  public static readonly users = {
    getList: '/auth/getManyUser',
    getOne: '/auth/getUser',
    update: '/auth/updateRoleUser',
    create: '/auth/createRoleUser',
  }
  public static readonly category = {
    drugKnowledge: '/pharma/category/kien-thuc-ve-thuoc',
    detailDrugKnowledge: '/pharma/post/',
    getList: '/pharma/category',
    searchCategory: '/pharma/category/search',
  }
  public static readonly news = {
    getList: '/pharma/post/search',
    newsDetailItem: '/pharma/post/',
    getProductSuggest: '/pharma/post/product-suggest',
  }
  public static readonly brand = {
    getList: '/pharma/brand/list',
    getDetail: '/pharma/brand/detail/',
  }
  public static readonly userAddress = {
    getList: '/pharma/address',
    getItem: (id: string | number) => `/pharma/address/${id}`,
    putItem: '/pharma/address/',
    addItem: '/pharma/address',
    deleteItem: '/pharma/address/',
  }
  public static readonly userProductWishList = {
    getList: '/pharma/wish-list',
    getItem: '/pharma/wish-list/',
    addItem: '/pharma/wish-list',
    updateItem: '/pharma/wish-list/',
    deleteItem: '/pharma/wish-list/',
    addToItem: '/pharma/wish-list/add-item',
    deleteFromItem: '/pharma/wish-list/delete-item',
    deleteAllFromItem: '/pharma/wish-list/delete-all-item',
    getWishListRelatedProduct: '/pharma/wish-list/related-products',
  }
  public static readonly productDetail = {
    getItem: '/pharma/product/',
    getItemReview: '/pharma/product/review/',
    getSimilarList: '/pharma/product/category/',
    getRecommendList: '/pharma/product/recommend/',
    getSeenList: '/pharma/product/customer/viewed',
    getFlashSaleList: '/pharma/product/search',
    getCampaignList: '/pharma/product/search',
    getCategoryList: '/pharma/product/search',
    getSuggestTodayList: '/pharma/product/search',
    getListByBuyTime: '/pharma/product/search',
    getSuggestByOrder: '/pharma/order/category/',
    getMostViewProduct: '/pharma/product/most-viewed-product',
  }

  public static readonly cart = {
    getCart: '/pharma/cart',
    addItem: '/pharma/cart/add-item',
    deleteItem: '/pharma/cart/delete-item',
    updateItem: '/pharma/cart/update-item',
    selectedAll: '/pharma/cart/select-all',
    updatePromotion: '/pharma/cart/update-promotion',
    addWishListItemToCart: '/pharma/wish-list/add-to-cart',
  }

  public static readonly order = {
    orderList: '/pharma/order/search',
    orderDetail: '/pharma/order/detail/',
    createOrder: '/pharma/order',
    searchOrder: '/pharma/order/search',
    updateOrder: '/pharma/order/',
    confirmOrder: '/pharma/order/confirm',
    buyNow: '/pharma/order',
    createOrderDetail: '/pharma/order/',
    addAddressNoUser: '/pharma/address/guest',
    cancelOrder: '/pharma/order/cancel',
    returnOrder: '/pharma/order-request-return',
    getReturnReason: '/pharma/reason-template',
    reOrder: '/pharma/order/re-order',
    reviewOrder: '/pharma/order/review',
  }

  public static readonly store = {
    getList: '/pharma/shop',
    searchList: '/pharma/shop/search',
  }

  public static readonly medicine = {
    getMedicineList: '/pharma/prescription/get-list-medicine',
    searchMedicine: '/pharma/prescription/search-medicine',
  }

  public static readonly searchDug = {
    search: '/pharma/product/medicine/search',
    historySearch: '/pharma/product/medicine/search/histories',
    banners: '/pharma/product/medicine/banners',
  }
  public static readonly prescription = {
    createItem: '/pharma/prescription',
    getItem: '/pharma/prescription',
    getList: '/pharma/prescription/search',
    deleteItem: '/pharma/prescription',
  }

  public static readonly home = {
    setting: '/pharma/setting',
  }

  public static readonly listProduct = {
    getSimilarList: '/pharma/product/category/',
    getSeenList: '/pharma/product/customer/viewed',
    getFlashSaleList: '/pharma/product/search',
    getCampaignList: '/pharma/product/search',
    getCategoryList: '/pharma/product/search',
    getSuggestTodayList: '/pharma/product/search',
  }

  public static readonly homeSearch = {
    homeSearch: '/pharma/search',
    getSuggest: '/pharma/search',
    getHistorySearch: '/pharma/search/history',
  }

  public static readonly voucher = {
    getList: '/pharma/promotion',
    getItem: (id: string | number) => '/pharma/promotion/' + id,
    getListGroup: '/pharma/promotion/group',
    saveVoucher: '/pharma/promotion/customer-save',
  }

  public static readonly accumulationHistory = {
    getList: '/auth/accumulation_histories',
  }

  public static readonly userNotify = {
    getList: '/pharma/notifications',
    getCertainList: '/pharma/notifications/search',
    getItem: '/pharma/notifications/',
    readAll: '/pharma/notifications/read-all',
    deleteAll: '/pharma/notifications/delete-all',
    deleteItem: '/pharma/notifications/',
    countUnreadTotal: '/pharma/notifications/count-unread',
  }

  public static readonly card = {
    getList: '/setting/list-card',
  }

  public static readonly comment = {
    comment: '/pharma/comment',
    search: '/pharma/comment/search',
  }

  public static readonly regularSchedule = {
    getList: '/pharma/order-frequency',
    getItem: '/pharma/order-frequency/',
    updateItem: '/pharma/order-frequency/',
    deleteItem: '/pharma/order-frequency/',
    getListConfirm: '/pharma/order-frequency/get-frequency',
    updateConfirmItem: '/pharma/order-frequency/update-frequency',
  }

  public static readonly bank = {
    getList: '/banks',
  }

  public static readonly userReview = {
    productReview: '/pharma/review/search',
    shippingReview: '/pharma/shipping-review/search',
    consultReview: '/pharma/consult-review/search',
    deleteConsultReview: '/pharma/consult-review/',
    deleteShippingReview: '/pharma/shipping-review/',
    deleteProductReview: '/pharma/review/',
  }

  public static readonly userProductFavoriteList = {
    getList: '/pharma/favorite/search',
    addItem: '/pharma/favorite',
    deleteItem: '/pharma/favorite',
    getFilters: '/pharma/product/filters',
    getRelatedProducts: '/pharma/favorite/related-products',
  }
  public static readonly userProductSeenList = {
    getList: '/pharma/product/customer/viewed-products',
    getFilters: '/pharma/product/filters',
  }

  public static readonly userProductComboList = {
    getList: '/pharma/combo',
    getRelatedProducts: '/pharma/combo/related-products',
  }

  public static readonly popup = {
    getByLang: '/pharma/popup/getByLang',
  }
}

export default Network
