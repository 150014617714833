import { createSlice } from '@reduxjs/toolkit'
import { getHomeSetting } from '../../../app/features/home/home-api'
import { RootState } from '../../../app/store'
import { toastSuccessErr } from '../../../helpers/toast'
import {
  BannerItemType,
  BrandListItemType,
  CampaignSetting,
  CategorySetting,
  FlashSale,
  MenuTypeSetting,
  OrderFrequencyOption,
  QuickAccessMenuType,
  SettingProductType,
} from '../../../models/home'

type StateType = {
  loading: boolean
  banner: BannerItemType[]
  brands: BrandListItemType[]
  campaign: CampaignSetting[]
  category: CategorySetting[]
  flash_sale: FlashSale[]
  menu: MenuTypeSetting[]
  order_frequency_option: OrderFrequencyOption[]
  quick_access_menu: QuickAccessMenuType[]
  homeSettingProduct: SettingProductType[]
}

const initialState: StateType = {
  loading: false,
  banner: [],
  brands: [],
  campaign: [],
  category: [],
  flash_sale: [],
  menu: [],
  order_frequency_option: [],
  quick_access_menu: [],
  homeSettingProduct: [],
}

export const slice = createSlice({
  name: 'homePage',
  initialState: initialState,
  reducers: {
    // changeRelativeAddressList: (state, action) => {
    //   state.relativeAddressList = action.payload
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(getHomeSetting.pending, (state) => {
        state.loading = true
      })
      .addCase(getHomeSetting.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200 && action?.payload?.data) {
          const {
            banner,
            brands,
            campaign,
            category,
            flash_sale,
            menu,
            order_frequency_option,
            quick_access_menu,
            homeSettingProduct,
          } = action.payload.data
          state.loading = false ?? []
          state.banner = banner ?? []
          state.brands = brands ?? []
          state.campaign = campaign ?? []
          state.category = category ?? []
          state.flash_sale = flash_sale ?? []
          state.menu = menu ?? []
          state.order_frequency_option = order_frequency_option ?? []
          state.quick_access_menu = quick_access_menu ?? []
          state.homeSettingProduct = homeSettingProduct ?? []
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
      })
      .addCase(getHomeSetting.rejected, (state) => {
        state.loading = false
      })
  },
})

export const selectHomeStore = (state: RootState) => state[slice.name]

export const HomePageReducer = slice.reducer
