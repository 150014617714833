import ic_company from '../assets/icons/CartScreen/ic_company.svg'
import ic_company_white from '../assets/icons/CartScreen/ic_company_white.svg'
import ic_home from '../assets/icons/CartScreen/ic_home.svg'
import ic_home_white from '../assets/icons/CartScreen/ic_home_white.svg'
import ic_dropdown1 from '../assets/icons/Partial/ic_dropdown1.svg'
import ic_dropdown2 from '../assets/icons/Partial/ic_dropdown2.svg'
import ic_dropdown3 from '../assets/icons/Partial/ic_dropdown3.svg'
import ic_dropdown4 from '../assets/icons/Partial/ic_dropdown4.svg'
import ic_dropdown7 from '../assets/icons/Partial/ic_dropdown7.svg'
import ic_menu from '../assets/icons/PersonalNotify/ic_menu.svg'
import ic_menu_blue from '../assets/icons/PersonalNotify/ic_menu_blue.svg'
import ic_sale from '../assets/icons/PersonalNotify/ic_sale.svg'
import ic_sale_blue from '../assets/icons/PersonalNotify/ic_sale_blue.svg'
import ic_sale_orange from '../assets/icons/PersonalNotify/ic_sale_orange.svg'
import ic_setting from '../assets/icons/PersonalNotify/ic_setting.svg'
import ic_setting_blue from '../assets/icons/PersonalNotify/ic_setting_blue.svg'
import ic_system from '../assets/icons/PersonalNotify/ic_system.svg'
import ic_news from '../assets/icons/PersonalNotify/ic_news.svg'
import ic_event from '../assets/icons/PersonalNotify/ic_event.png'
import ic_remind from '../assets/icons/PersonalNotify/ic_remind.svg'
import ic_transfer from '../assets/icons/PersonalNotify/ic_transfer.svg'
import ic_transfer_blue from '../assets/icons/PersonalNotify/ic_transfer_blue.svg'
import ic_account from '../assets/icons/PersonalScreen/ic_account.svg'
import ic_account_blue from '../assets/icons/PersonalScreen/ic_account_blue.svg'
import ic_location from '../assets/icons/PersonalScreen/ic_location.svg'
import ic_location_blue from '../assets/icons/PersonalScreen/ic_location_blue.svg'
import ic_profile from '../assets/icons/PersonalScreen/ic_profile.svg'
import ic_profile_blue from '../assets/icons/PersonalScreen/ic_profile_blue.svg'
import ja_lang from '../assets/icons/ja_lang.png'
import flag_vi from '../assets/icons/vi_lang.png'
import img_mastercard from '../assets/images/CheckoutScreen/img_mastercard.png'
import img_napas from '../assets/images/CheckoutScreen/img_napas.png'
import img_visa from '../assets/images/CheckoutScreen/img_visa.png'
import img_vnpay from '../assets/images/CheckoutScreen/img_vnpay.png'
import { default as fake_img, default as product } from '../assets/images/DetailProductScreen/fake_product3.png'
import campaign_thumb from '../assets/images/PersonalScreen/campaign_thumb.png'
import campaign_thumb2 from '../assets/images/PersonalScreen/campaign_thumb2.png'
import ic_menu1 from '../assets/images/PersonalScreen/ic_menu1.png'
import ic_menu2 from '../assets/images/PersonalScreen/ic_menu2.png'
// import ic_menu3 from '../assets/images/PersonalScreen/ic_menu3.png'
import ic_menu4 from '../assets/images/PersonalScreen/ic_menu4.png'
import ic_menu5 from '../assets/images/PersonalScreen/ic_menu5.png'
import ic_menu6 from '../assets/images/PersonalScreen/ic_menu6.png'
import ic_menu7 from '../assets/images/PersonalScreen/ic_menu7.png'
import ic_menu9 from '../assets/images/PersonalScreen/ic_menu9.png'
import omi_product_banner from '../assets/images/banners/products/omi_product_banner.png'

import ic_box from '../assets/icons/BrandingScreen/ic_box.svg'
import ic_box_active from '../assets/icons/BrandingScreen/ic_box_active.svg'
import ic_info from '../assets/icons/BrandingScreen/ic_info.svg'
import ic_info_active from '../assets/icons/BrandingScreen/ic_info_active.svg'
import ic_saleBran from '../assets/icons/BrandingScreen/ic_sale.svg'
import ic_saleBran_active from '../assets/icons/BrandingScreen/ic_sale_active.svg'
import ic_all from '../assets/icons/PersonalPoint/ic_all.svg'
import ic_all_active from '../assets/icons/PersonalPoint/ic_all_active.svg'
import ic_in from '../assets/icons/PersonalPoint/ic_in.svg'
import ic_in_active from '../assets/icons/PersonalPoint/ic_in_active.svg'
import ic_out from '../assets/icons/PersonalPoint/ic_out.svg'
import ic_out_active from '../assets/icons/PersonalPoint/ic_out_active.svg'
import ic_saleVorcher from '../assets/icons/PersonalVoucherScreen/ic_sale.svg'
import ic_sale_active from '../assets/icons/PersonalVoucherScreen/ic_sale_active.svg'
import ic_save from '../assets/icons/PersonalVoucherScreen/ic_save.svg'
import ic_save_active from '../assets/icons/PersonalVoucherScreen/ic_save_active.svg'
import ic_time from '../assets/icons/PersonalVoucherScreen/ic_time.svg'
import ic_time_active from '../assets/icons/PersonalVoucherScreen/ic_time_active.svg'
import ic_use from '../assets/icons/PersonalVoucherScreen/ic_use.svg'
import ic_use_active from '../assets/icons/PersonalVoucherScreen/ic_use_active.svg'
import img_rank1 from '../assets/images/OmiPoint/img_rank1.svg'
import img_rank2 from '../assets/images/OmiPoint/img_rank2.svg'
import img_rank3 from '../assets/images/OmiPoint/img_rank3.svg'
import img_rank4 from '../assets/images/OmiPoint/img_rank4.svg'

import { ProductDetailType, SelectItemType } from '../models/types'
import { ROUTER } from '../router/config'

// constant
export const KEY_AUTH = 'TOKEN_OMIPHARMA'
export const KEY_USER = 'USER_OMIPHARMA'
export const KEY_REMEMBER = 'REMEMBER_OMIPHARMA'
export const languageVI = 'vi'
export const languageJA = 'jp'

export const optionGender = [
  { value: 1, title: 'male' },
  { value: 2, title: 'female' },
]

export const GREAT_DEAL_TYPE = 3
export const GIFT_TYPE = 2

export const userScreenMenu = [
  {
    id: 0,
    title: 'userScreenMenu.myOrder',
    content: 'userScreenMenu.content1',
    thumb: ic_menu1,
    link: ROUTER.ORDER_SCREEN,
  },
  {
    id: 1,
    title: 'userScreenMenu.infoAcc',
    content: 'userScreenMenu.content2',
    thumb: ic_menu2,
    link: ROUTER.ACCOUNT_SCREEN,
  },
  // {
  //   id: 2,
  //   title: 'userScreenMenu.payment',
  //   content: 'userScreenMenu.content3',
  //   thumb: ic_menu3,
  //   link: ROUTER.PAYMENT_METHOD_SCREEN,
  // },
  {
    id: 3,
    title: 'userScreenMenu.merchandiseList',
    content: 'userScreenMenu.content4',
    thumb: ic_menu4,
    link: ROUTER.USER_PRODUCT_SCREEN,
  },
  {
    id: 4,
    title: 'userScreenMenu.regularPurchasingSchedule',
    content: 'userScreenMenu.content5',
    thumb: ic_menu5,
    link: ROUTER.USER_REGULAR_SCREEN,
  },
  {
    id: 5,
    title: 'userScreenMenu.notifyMe',
    content: 'userScreenMenu.content6',
    thumb: ic_menu6,
    link: ROUTER.USER_NOTIFICATION_SCREEN,
  },
  {
    id: 6,
    title: 'userScreenMenu.reviewMe',
    content: 'userScreenMenu.content7',
    thumb: ic_menu7,
    link: ROUTER.USER_REVIEW_SCREEN,
  },
  // {
  //   id: 7,
  //   title: 'Omicaring',
  //   content: 'Chi tiết đơn hàng của bạn, mã vận đơn, chi tiết đơn vận, thời gian giao hàng',
  //   thumb: ic_menu8,
  //   link: '/user',
  // },
  {
    id: 8,
    title: 'userScreenMenu.managePre',
    content: 'userScreenMenu.content8',
    thumb: ic_menu9,
    link: ROUTER.MANAGE_PRESCRIPTION_SCREEN,
  },
]

export const userAccountScreenTabList = [
  {
    id: 0,
    label: 'userScreenMenu.infoAcc',
    icon: ic_account,
    activeIcon: ic_account_blue,
  },
  {
    id: 1,
    label: 'userScreenMenu.myInfo',
    icon: ic_profile,
    activeIcon: ic_profile_blue,
  },
  {
    id: 2,
    label: 'userScreenMenu.listAddress',
    icon: ic_location,
    activeIcon: ic_location_blue,
  },
  // {
  //   id: 3,
  //   label: 'Danh sách người thân',
  //   icon: ic_family,
  //   activeIcon: ic_family_blue,
  // },
]

export const addressTypeList = [
  { id: 1, label: 'fastCart.house', icon: ic_home, activeIcon: ic_home_white },
  { id: 2, label: 'fastCart.office', icon: ic_company, activeIcon: ic_company_white },
]

export const userShopReviewHeader: Array<SelectItemType> = [
  { id: 1, title: 'store' },
  { id: 2, title: 'review' },
  { id: 3, title: 'comment' },
  { id: 5, title: 'action' },
]

export const userServiceReviewHeader: Array<SelectItemType> = [
  { id: 1, title: 'userReview.header_service_review_1' },
  { id: 2, title: 'userReview.header_service_review_2' },
  { id: 3, title: 'userReview.header_service_review_3' },
  { id: 4, title: 'userReview.header_service_review_4' },
]

export const userNotifyHeaderTable: Array<SelectItemType> = [
  { id: 1, title: 'typeNoti' },
  { id: 2, title: 'content' },
  { id: 3, title: 'operation' },
]

export const userNotifyTypeList = {
  promotion: { id: 1, type: 'promotions', title: 'promotion', icon: ic_sale_orange },
  delivery: { id: 2, type: 'order', title: 'delivery', icon: ic_transfer_blue },
  omiResponse: { id: 3, type: 'omi_system', title: 'resFromOmi', icon: ic_system },
  news: { id: 4, type: 'News', title: 'newsType', icon: ic_news },
  event: { id: 5, type: 'Event', title: 'event', icon: ic_event },
  remind: { id: 6, type: 'Remind', title: 'remind', icon: ic_remind },
}

export const userRegularBuyHeaderTable: Array<SelectItemType> = [
  { id: 1, title: 'userRegular.columnName' },
  { id: 2, title: 'userRegular.columnProduct' },
  { id: 3, title: 'userRegular.columnQuantity' },
  { id: 4, title: 'userRegular.columnPeriod' },
  { id: 5, title: 'userRegular.columnTotal' },
  { id: 6, title: 'userRegular.columnAction' },
]

export const userRegularBuyConfirmHeaderTable: Array<SelectItemType> = [
  { id: 1, title: 'userRegular.columnName' },
  { id: 2, title: 'userRegular.columnProduct' },
  { id: 3, title: 'userRegular.columnQuantity' },
  { id: 4, title: 'userRegular.columnPeriod' },
  { id: 5, title: 'userRegular.columnTotal' },
  { id: 6, title: 'userRegular.columnConfirm' },
]

export const paymentCardType: Array<SelectItemType> = [
  { id: 0, title: 'all' },
  { id: 1, title: 'cardCredit' },
  { id: 2, title: 'e-wallets' },
]

export const paymentOrderType: Array<SelectItemType> = [
  { id: 0, title: 'all' },
  { id: 1, title: 'noDiscounts' },
  { id: 2, title: 'discounts' },
  { id: 3, title: 'buyPeriodically' },
]

export const historyPaymentHeaderTable: Array<SelectItemType> = [
  { id: 0, title: 'paid' },
  { id: 1, title: 'raiseMoney' },
  { id: 2, title: 'paymentOrder' },
  { id: 3, title: 'operation' },
]

export const orderHeaderTable: Array<SelectItemType> = [
  { id: 0, title: '' },
  { id: 1, title: 'nameProduct' },
  { id: 2, title: 'deliveryCycle' },
  { id: 3, title: 'priceProduct' },
  { id: 4, title: 'quantity' },
  { id: 6, title: 'infoMoney' },
]

export const userProductReviewHeader: Array<SelectItemType> = [
  { id: 1, title: 'userReview.header_product_review_1' },
  { id: 2, title: 'userReview.header_product_review_2' },
  { id: 3, title: 'userReview.header_product_review_3' },
  { id: 4, title: 'userReview.header_product_review_4' },
  { id: 5, title: 'userReview.header_product_review_5' },
]

export const userPharmacistReviewHeader: Array<SelectItemType> = [
  { id: 1, title: 'userReview.header_consult_review_1' },
  { id: 2, title: 'userReview.header_consult_review_2' },
  { id: 3, title: 'userReview.header_consult_review_3' },
  { id: 4, title: 'userReview.header_consult_review_4' },
]

export const userNotifyTabList = [
  { id: 1, type: '', title: 'all', icon: ic_menu, activeIcon: ic_menu_blue },
  { id: 2, type: 'promotions', title: 'promotion', icon: ic_sale, activeIcon: ic_sale_blue },
  { id: 3, type: 'order', title: 'delivery', icon: ic_transfer, activeIcon: ic_transfer_blue },
  { id: 4, type: 'omi_system', title: 'resFromOmi', icon: ic_setting, activeIcon: ic_setting_blue },
]

export const orderTabVoucher = [
  { id: 0, label: 'userVoucher.currentProgram', iconActive: ic_sale_active, icon: ic_saleVorcher },
  { id: 1, label: 'userVoucher.saved', iconActive: ic_save_active, icon: ic_save },
  { id: 2, label: 'userVoucher.used', iconActive: ic_use_active, icon: ic_use },
  { id: 3, label: 'userVoucher.expiredTab', iconActive: ic_time_active, icon: ic_time },
]

export const PROMOTION_TYPE_USED = 2

export const policyNavList = [
  { id: 0, label: 'deliveryPolicy', link: ROUTER.DELIVERY_POLICY_SCREEN },
  { id: 1, label: 'exchangePolicy', link: ROUTER.RETURN_POLICY_SCREEN },
  { id: 2, label: 'privacyPolicy', link: ROUTER.PRIVACY_POLICY_SCREEN },
  { id: 3, label: 'omiPointPolicy', link: ROUTER.OMI_POINT_SCREEN },
  { id: 4, label: 'serviceTerm', link: ROUTER.TERMS_OF_SERVICE_SCREEN },
  { id: 5, label: 'orderGuild', link: ROUTER.ORDERING_GUIDE_SCREEN },
]

export const ListDropdownUser = [
  { id: 0, icon: ic_dropdown1, title: 'header.omiPoint', text: '30', link: '/user/point' },
  { id: 1, icon: ic_dropdown2, title: 'header.myVoucher', text: '30', link: 'user/voucher' },
  { id: 2, icon: ic_dropdown3, title: 'header.myOmiCare', link: '/user' },
  { id: 3, icon: ic_dropdown4, title: 'header.merchandiseList', link: '/user/product' },
  // { id: 4, icon: ic_dropdown5, title: 'header.orderHistory', link: 'user/purchase' },
  // { id: 5, icon: ic_dropdown6, title: 'header.autoRepurchase', link: '' },
  { id: 6, icon: ic_dropdown7, title: 'header.myNotification', link: '/user/notify' },
]

//Cần đổi ảnh JA và key
export const LanguageList = [
  { id: languageVI, icon: flag_vi, alt: 'flag vn', name: 'header.vietnamese' },
  { id: languageJA, icon: ja_lang, alt: 'flag jp', name: 'header.japan' },
]

export const YOUTUBE_LINK = 'https://www.youtube.com/embed/KqcGuMeTg5M'
export const YOUTUBE_LINK_PAGE = 'https://www.youtube.com/@nhathuocomipharma'
export const FB_LINK = 'https://www.facebook.com/OmiPharmaVietNam?mibextid=LQQJ4d'
export const DIABETES_KNOWLEDGE_LINK = 'https://kienthuctieuduong.vn/'

export const fakeProductList: ProductDetailType[] = [
  {
    id: '0',
    mainImg: fake_img,
    allow_frequency: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '1',
    mainImg: fake_img,
    allow_frequency: true,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    sold: 1000,
    rating: 3.5,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '2',
    mainImg: fake_img,
    allow_frequency: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '3',
    mainImg: fake_img,
    allow_frequency: true,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '4',
    mainImg: fake_img,
    allow_frequency: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 4,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '5',
    mainImg: fake_img,
    allow_frequency: true,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 2.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '6',
    mainImg: fake_img,
    allow_frequency: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 1.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '7',
    mainImg: fake_img,
    allow_frequency: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '8',
    mainImg: fake_img,
    allow_frequency: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
  {
    id: '9',
    mainImg: fake_img,
    allow_frequency: false,
    name: 'Dầu tẩy trang Olive & Argan Deve Nhật bản (chai 500ml)',
    isDrug: false,
    isFavorite: false,
    save: 30000,
    rating: 3.5,
    sold: 1000,
    mainProductType: {
      id: '124abc',
      name: 'Lọ 39 viên',
      price: 179000,
    },
    discountPercent: 50,
    discountPrice: 159500,
    originalPrice: 319000,
  },
]

export const fakeBannerOmiProduct = [
  { id: 0, img: omi_product_banner },
  { id: 1, img: omi_product_banner },
]

export const fakeCurrentPromotionItem = [
  { id: 1, name: 'Promotion 1', banner: campaign_thumb, productList: fakeProductList },
  { id: 2, name: 'Promotion 2', banner: campaign_thumb2, productList: fakeProductList },
]

const reviewShopItem = {
  reviewId: 1,
  shopDetail: {
    id: 1,
    name: 'CS1: Green Stars',
    address: 'Phạm Văn Đồng, Hà Nội',
    img: product,
  },
  reviewStar: 3,
  reviewContent:
    'Thành phần chính của sữa tắm nam PharmaAct Super Extra Cool Body Soap của Nhật là chiết xuất lá bạc hà tự nhiên với gấp đôi hàm lượng tinh chất Menthol s',
}

export const fakeUserShopReviewData = [
  { ...reviewShopItem },
  { ...reviewShopItem, reviewId: 2, reviewStar: 2 },
  { ...reviewShopItem, reviewId: 3, reviewStar: 5 },
]

export const fakeListCardData = [
  { id: 0, title: 'cardCredit', content: '**** **** **** 4968', thumb: img_visa },
  { id: 1, title: 'cardCredit', content: '**** **** **** 4968', thumb: img_mastercard },
  { id: 2, title: 'domesticCardPayment', content: '**** **** **** 4968', thumb: img_napas },
  { id: 3, title: 'e-wallets', thumb: img_vnpay },
]

const historyPaymentItem = {
  id: 0,
  paymentCard: { id: 0, title: 'Thẻ tín dụng', content: '**** **** **** 4968', thumb: img_visa },
  paymentDate: '2021/10/14 2:40pm',
  paymentCost: 300000,
  orderCode: 'KV49583925822',
  allow_frequency: true,
}

export const fakeHistoryPaymentList = [
  historyPaymentItem,
  { ...historyPaymentItem, id: 1, paymentCard: fakeListCardData[1], paymentCost: 400000 },
  { ...historyPaymentItem, id: 2, paymentCard: fakeListCardData[2], paymentCost: 500000 },
  { ...historyPaymentItem, id: 3, paymentCard: fakeListCardData[3], paymentCost: 600000 },
]

export const arrRank = [
  {
    id: 1,
    icon: img_rank1,
    title: 'userOmiPoint.standardRank',
    name: 'userOmiPoint.friend',
    color: '#AA6F5C',
  },
  {
    id: 2,
    icon: img_rank2,
    title: 'userOmiPoint.goldRank',
    name: 'userOmiPoint.closeFriend',
    color: '#FBC654',
  },
  {
    id: 3,
    icon: img_rank3,
    title: 'userOmiPoint.platinumRank',
    name: 'userOmiPoint.companion',
    color: '#ABD0D5FF',
  },
  {
    id: 4,
    icon: img_rank4,
    title: 'userOmiPoint.diamondRank',
    name: 'userOmiPoint.soulmate',
    color: '#abd0d5',
  },
]

export const tabPointScreen = [
  { id: 0, title: 'all', icon: ic_all, iconActive: ic_all_active },
  { id: 1, title: 'received', icon: ic_in, iconActive: ic_in_active },
  { id: 2, title: 'used', icon: ic_out, iconActive: ic_out_active },
]

export const tabBrandScreen = [
  { id: 0, title: 'promotion', icon: ic_saleBran, iconActive: ic_saleBran_active },
  { id: 1, title: 'allProduct', icon: ic_box, iconActive: ic_box_active },
  { id: 2, title: 'infoBrand', icon: ic_info, iconActive: ic_info_active },
]

//Search screen
export const listFilterSearch = [
  {
    value: '1',
    label: 'product',
  },
  {
    value: '5',
    label: 'diseaseSymptoms',
  },
  {
    value: '3',
    label: 'liveHealthy',
  },
]

export const listFilterPrice = [
  {
    value: 'asc',
    label: 'asc',
  },
  {
    value: 'desc',
    label: 'desc',
  },
]

export const listFilterOrder = [
  {
    value: 'desc',
    label: 'newest',
  },
  {
    value: 'asc',
    label: 'A-Z',
  },
]

export const fakeBannerPromotionList = [
  { id: 0, img: omi_product_banner },
  { id: 1, img: omi_product_banner },
  { id: 3, img: omi_product_banner },
  { id: 4, img: omi_product_banner },
]
// end example

export const PAGE_SIZE = 15
