import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState, AppDispatch } from './store'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { getToken } from '../helpers/cookieHelper'
import { KEY_AUTH } from '../constant'
import { getTemToken } from '../helpers/storage'
import { getLang } from '../i18n'
import { UpDateAddItemCartDtoType } from '../models/cart'
import { useEffect, useState } from 'react'
import { addCartItem, getCartDetail } from './features/cart/cart-api'
import { useNavigate } from 'react-router-dom'
import { toastSuccessErr } from '../helpers/toast'
import { useTranslation } from 'react-i18next'
import { ROUTER } from '../router/config'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

//Use if want to use axios to fetch instead of fetchBaseQuery
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    const accessToken = getToken(KEY_AUTH)
    const temToken = getTemToken()
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: {
          Language: getLang(),
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
          'Temp-Customer-Token': !accessToken && temToken ? temToken : undefined,
        },
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export function useAddToCart() {
  const dispatch = useAppDispatch()
  const [state, setState] = useState()
  const navigate = useNavigate()
  // eslint-disable-next-line
  let cart = document.querySelector('#cartHeader') as any
  const speed = 1000,
    curveDelay = 300,
    position = 'fixed'
  function addToCart(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    img: string,
    data: UpDateAddItemCartDtoType,
    product?: any,
  ) {
    if (product && product?.variants?.length > 1) return navigate(ROUTER.PRODUCT_DETAIL + product.slug)

    // eslint-disable-next-line
    let btnY = position === 'fixed' ? e.currentTarget.getBoundingClientRect().top : e.currentTarget.offsetTop,
      // eslint-disable-next-line
      btnX = position === 'fixed' ? e.currentTarget.getBoundingClientRect().left : e.currentTarget.offsetLeft,
      // eslint-disable-next-line
      flyingBtn = e.currentTarget.cloneNode() as HTMLElement

    flyingBtn.classList.add('flyingBtn')
    flyingBtn.classList.add('fa')
    flyingBtn.classList.add('fa-shopping-cart')
    flyingBtn.style.color = '#1daca6'
    flyingBtn.style.background = `#ffff`
    flyingBtn.style.position = position
    flyingBtn.style.top = `${btnY}px`
    flyingBtn.style.left = `${btnX}px`
    flyingBtn.style.opacity = '1'
    flyingBtn.style.transition = `all ${speed / 1000}s ease, top ${(speed + curveDelay) / 1000}s ease, left ${
      speed / 1000
    }s ease, transform ${speed / 1000}s ease ${(speed - 10) / 1000}s`
    document.body.appendChild(flyingBtn)

    flyingBtn.style.top = `${cart.offsetTop + cart.offsetHeight - 16}px`
    flyingBtn.style.left = `${cart.offsetLeft + cart.offsetWidth - 16}px`
    flyingBtn.style.height = '30px'
    flyingBtn.style.width = '30px'
    flyingBtn.style.border = 'none'
    flyingBtn.style.borderRadius = '8px'

    flyingBtn.style.transform = 'scale(0)'
    setTimeout(() => {
      flyingBtn.remove()
      actionAddToCart(data)
    }, speed * 1.5)
  }

  function actionAddToCart(data: UpDateAddItemCartDtoType) {
    dispatch(addCartItem(data)).then((res: any) => {
      setState(res)
      if (res?.payload?.code === 200) {
        dispatch(getCartDetail())
      }
    })
  }

  return [state, addToCart]
}

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()
  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true)
        toastSuccessErr({ message: t('copySuccess'), type: 'success' })
      })
      .catch(() => {
        toastSuccessErr({ message: t('error'), type: 'error' })
      })
  }

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false)
      }, 2000)

      return () => clearTimeout(timeout)
    }
  }, [copied])

  return [copied, copyToClipboard]
}
